<template>
    <div class='top'>
        <img :src='img' style="width:100%;" />
            <a-breadcrumb separator=">">
            位置：
      <a-breadcrumb-item>首页</a-breadcrumb-item>
     <a-breadcrumb-item>{{ch}}</a-breadcrumb-item>
    </a-breadcrumb>
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        位置：
		  <el-breadcrumb-item>首页</el-breadcrumb-item>
		  <el-breadcrumb-item>{{ch}}</el-breadcrumb-item>
		</el-breadcrumb> -->
    </div>
</template>

<script>
export default {
    props:{
        img:String,
        ch:String
    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
.top{
    width:100%;
}
.el-breadcrumb{
    width:100%;
    display:flex;
    justify-content:flex-end;
    padding:10px 10% 10px 0;
}
.ant-breadcrumb{
    width:100%;
    display:flex;
    justify-content:flex-end;
    padding:10px 10% 10px 0;
}
</style>
