<template>
    <div class="detail">
        <Top v-if="columnList.img_info_list" :img="'/gsmm/api/v1/' + columnList.img_info_list[0].url" :ch="columnList.name" />
        <div class="content">
         <div class="title" v-if="$route.query.detailId">{{detail.title}}</div>
             <div class="title" v-if="$route.query.successId">{{successdetail.title}}</div>
             <Background />
           <div class="time" v-if="$route.query.detailId">
               	{{detail.create_time.split('-')[0]+'年'+detail.create_time.split('-')[1]+'月'+detail.create_time.split(' ')[0].split('-')[2]+'日'}}
           </div>
           <div class="time" v-if="$route.query.successId">
               	{{successdetail.create_time.split('-')[0]+'年'+successdetail.create_time.split('-')[1]+'月'+successdetail.create_time.split(' ')[0].split('-')[2]+'日'}}
           </div>
            <div class="ql-snow">
                <div class="ql-editor" v-if="$route.query.detailId" v-html="detail.content">{{detail.content}}</div>
                 <div class="ql-editor" v-if="$route.query.successId" v-html="successdetail.content">{{successdetail.content}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Top from '../components/top.vue'
import Background from '../components/background.vue'
import { mapState, mapActions } from 'vuex';
export default {
    data() {
        return {
            // ch:'数字赋能'
        };
    },
    components: {
        Top,
        Background
    },
    computed: {
        ...mapState(['columnList', 'detail','successdetail'])
    },
    mounted() {
        this.handleColumn(this.$route.query.id)
        if(this.$route.query.successId){
            this.handleDetail1(this.$route.query.successId)
        }else{
              this.handleDetail(this.$route.query.detailId)
        }
    },
    methods: {
        ...mapActions(['handleColumn', 'handleDetail','handleDetail1']),
    }
};
</script>

<style scoped lang='less'>
.detail {
    width: 100%;
    background-image: url("../../assets/bg.png");
    padding-bottom: 50px;
}
.content {
    padding: 10px 20% 20px 20%;
    .title{
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    .time{
        text-align:center;
        margin-bottom:10px;
        color:#666;
    }
}
</style>
